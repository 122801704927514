.application-sidebar {
    background-color: #efefef;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 20px;
    .sidebar-action {
        text-align: right;
        margin-bottom: 10px;
    }
    .sidebar-provider-logo {
        img {
            width: 100px;
            margin-bottom: 10px;
        }
    }
    h2, h3 {
        margin-bottom: 0;
    }
    p {
        font-size: 14px;
        span {
            font-weight: bold;
        }
    }
}