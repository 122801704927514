.payment-form{
    display: flex;
    flex-direction: column;
    .payment-form-item {
        margin-bottom: 10px;
    }
    .StripeElement {
        padding: 15px;
        box-shadow: $input-alternative-box-shadow;
        border-radius: 5px;
        input {
            font-size: 18px;
        }
    }
    label {
        font-size: 12px;
    }
    button {
        background: #32325d;
        border: 0px;
        padding: 10px;
        color: #ffffff;
        border-radius: 5px;
        margin-top: 10px;
        cursor: pointer;
        span {
            font-weight: bold;
        }
        &:hover {
            box-shadow: 0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08);
            background-color: #43458b;
        }
    }
}

.we-accept {
    text-align: center;
    margin-top: 20px;
    h5{
        font-size: 16px;
    }
    img {
        max-height: 50px;
        width: auto; 
       
    }
}

.powered-by {
    text-align: right;
    margin-bottom: 10px;
}