@include media-breakpoint-up(md) {
	.quote-form {
        flex-direction: row;
        display: flex;
        .form-item {
            flex: 1;
            margin-right: 10px;
        }
        .form-action {
            flex: 1;
            margin-top: 30px;
        }
    }
}