//
// Forms
//

@import "../core/forms/form";
@import "../core/forms/form-validation";
@import "../core/forms/input-group";

.formError {
    color: #ff0000;
    display: inline-block;
    margin-top: 5px;
    font-size: 12px;
    font-style: italic;
}

.search-bar{
    position: absolute;
    margin-top: -80px;
    right: 30px;
}