.faq-item {
    h4 {
        cursor: pointer;
        font-size: 1.35rem;
        font-weight: bold;;
    }
    strong{
        font-weight: bold;
    }
    li {
        font-weight: 300;
    }
}

.rdtPicker {
    min-width: 292px !important;
}

.nav-tabs a {
    cursor: pointer;
}