.steps {
    padding: 20px 0;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    .step-item {
        width: 250px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        &:before {
            content: '';
            display: inline-block;
            height: 10px;
            width: 250px;
            background-color: #ffffff;
            position: absolute;
            top: 45px;
            left: 100px;
            z-index: -1;
        }
        
        .step-count {
            display: inline-block;
            width: 100px;
            height: 100px;
            background-color: #ffffff;
            border-radius: 50%;
            color: theme-color("secondary");
            font-size: 50px;
            text-align: center;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
            border: 1px solid #ffffff;
        }
        .step-description {
            min-height: 48px;
            font-size: 14px;
            max-width: 150px
        }
        &.done-item {
            &:before {
                background-color: theme-color("secondary");
            }
            .step-count {
                background-color: theme-color("secondary");
                color: #ffffff;
                border: 1px solid #ffffff;
            }
        }
        &.last-item {
            &:before {
                display: none;
            }
        }
        &.current-item {
            .step-count {
                border-color: theme-color("secondary");
            }
        }
    }
    
}